.DeliveryPickItem {
}

.DeliveryPickItem__Sku {
  font-weight: 600;
}

.DeliveryPickItem_Details {
  display: flex;
  align-items: center;
}

.DeliveryPickItem_Details > *:not(:first-child)::before {
  content: '•';
  margin: 0 4px;
  color: #919eab; /* ink, lightest */
}

.DeliveryPickItem_Quantity {
  white-space: nowrap;
}

.DeliveryPickItem_Price {
  display: flex;
  min-width: 0;
  justify-content: flex-end;
}
@media (min-width: 640px) {
  .CustomerListItem__Main {
    display: flex;
  }

  .CustomerListItem__Main > *:not(:last-child) {
    margin-right: 20px;
  }

  .DeliveryPickItem_Details {
    flex: 1;
    justify-content: flex-end;
  }

  .DeliveryPickItem_Details > *:not(:first-child)::before {
    display: none;
  }

  .DeliveryPickItem_Quantity {
    min-width: 80px;
  }

  .DeliveryPickItem_Price {
    min-width: 168px;
  }
}
